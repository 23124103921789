import React, { useState } from "react"
import { Carousel, CarouselCaption, CarouselControl, CarouselIndicators, CarouselItem } from "reactstrap"
import "./ImageCarousel.css";

const ImageCarousel = ({items}) => {
    const [activeIndex, setActiveIndex] = useState(0)

    const next = () => {
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    const previous = () => {
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    const goToIndex = (newIndex) => {
        setActiveIndex(newIndex);
    }

    const slides = items.map((item) => {
        return (
            <CarouselItem
                key={item.src}
            >
                <img className="image-carousel-img" src={item.src} alt={item.altText} />
                <CarouselCaption/>
            </CarouselItem>
        )
    })

    return (
        <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
            
        >
            <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
            {slides}
            <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
            <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
        </Carousel>
    );
    
}

export default ImageCarousel