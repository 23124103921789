import React from "react";
import { Container } from "reactstrap";
import "./Produtos.css"
import ce1 from "../../assets/images/ce1.jpg"
import ce7 from "../../assets/images/ce7.jpg"
import ce5 from "../../assets/images/ce5.jpg"
import ce8 from "../../assets/images/ce8.jpg"
import ce2 from "../../assets/images/ce2.jpg"

const CercaEletrica = () => {
    const items = [
        {
            src: ce1, 
            alt: "trabalho realizado com cerca elétrica"
        },
        {
            src: ce2, 
            alt: "trabalho realizado com cerca elétrica"
        },
        {
            src: ce5, 
            alt: "trabalho realizado com cerca elétrica"
        },
        {
            src: ce7, 
            alt: "trabalho realizado com cerca elétrica"
        },
        {
            src: ce8, 
            alt: "trabalho realizado com cerca elétrica"
        }
    ]

    return (
        <Container className="produto-container">
            <div className="texto-produto">
                <h2 className="header-produto">Cerca Elétrica</h2>
                <article>
                    Esse equipamento virou item obrigatório em todo bom projeto de segurança eletrônica de quem deseja aliar mais segurança e restrição de acesso a locais. 
                    A cerca elétrica é um sistema de proteção bastante eficaz para residências, indicado inclusive para imóveis rurais ou urbanos onde as pessoas viajam 
                    constantemente ou ficam ausentes por longos períodos.<br /><br />
                    
                
                    A instalação de cerca elétrica é indicada tanto para pessoas que desejam proteger a sua residência, como para empresas e comércios que precisam 
                    de mais segurança. Os condomínios também são muito beneficiados, especialmente os que possuem uma área extensa, já que a cerca evita as invasões e 
                    também serve como alarme. Já nos imóveis rurais, elas ajudam tanto a proteger grandes propriedades quanto no confinamento do gado e outros animais de 
                    criação.<br /><br />
                </article>
            </div>
            
            {items && 
                <div>
                    <h2 className="header-cerca">Algumas instalações realizadas</h2>
                    <div className="produto-work-gallery">
                        {items && items.map((item) => (
                            <img src={item.src} alt={item.alt}></img>
                        ))}
                    </div>
                </div>
            }
            
        </Container>
    )
}
export default CercaEletrica