import React from "react";
import { Container } from "reactstrap";
import "./Produtos.css"
import rl1 from "../../assets/images/rl1.jpg"
import rl2 from "../../assets/images/rl2.jpg"
import rl3 from "../../assets/images/rl3.jpg"
import rl4 from "../../assets/images/rl4.jpg"
import rl7 from "../../assets/images/rl7.jpg"
import rl8 from "../../assets/images/rl8.jpg"

const RedeLaminada = () => {
    const items = [
        {
            src: rl1, 
            alt: "trabalho realizado com rede laminada"
        },
        {
            src: rl2, 
            alt: "trabalho realizado com rede laminada"
        },
        {
            src: rl3, 
            alt: "trabalho realizado com rede laminada"
        },
        {
            src: rl4, 
            alt: "trabalho realizado com rede laminada"
        },
        {
            src: rl7, 
            alt: "trabalho realizado com rede laminada"
        },
        {
            src: rl8, 
            alt: "trabalho realizado com rede laminada"
        }
    ]

    return (
        <Container className="produto-container">
            <div className="texto-produto">
                <h2 className="header-produto">Rede Laminada</h2>
                <article>
                    A rede laminada é uma tela feita com aço de elevada resistência ao corte e umidade, ou seja, não enferruja ao entrar em contato com a água. 
                    Toda a tela é soldada de modo a formar um painel sem pontos cegos. Outras características do material são suas lâminas com alto poder de corte 
                    e perfuração, semelhantes às da concertina. Por isso é extremamente eficaz em proteger espaços como quadras, terrenos diversos, empresas, 
                    alambrados, igrejas, escolas, entre muitos outros, reduzindo consideravelmente tentativas de invasões, roubos e furtos.<br/> <br/>

                    <b>Vantagens na utilização de rede laminada:</b><br /><br />
                    <b>Resistência</b><br />
                    Por ser feita em aço que é resistente a mudanças climáticas e a cortes e rompimentos do material, a Rede Laminada é durável e não necessita de 
                    grandes manutenções. <br/><br/>

                    <b>Segurança</b><br />
                    As redes laminadas ajudam a desestimular a invasão ao imóvel. O meliante ao ver que não há espaço suficiente para pular o muro e adentrar na 
                    residência acaba desistindo e os mais ousados que tentam invadir acabam feridos. Por isso a segurança do morador é uma das principais 
                    características.<br/><br/>

                    <b>Manutenção</b><br />
                    Por causa do material em que foi fabricada, a rede não necessita de grandes manutenções e assim faz com que seja econômica para a família.<br /><br />

                </article>
            </div>
            
            {items && 
                <div>
                    <h2 className="header-produto">Algumas instalações realizadas</h2>
                    <div className="produto-work-gallery">
                        {items && items.map((item) => (
                            <img src={item.src} alt={item.alt}></img>
                        ))}
                    </div>
                </div>
            }
            
        </Container>
    )
}
export default RedeLaminada