import React from "react";
import { Container } from "reactstrap";
import "./Produtos.css"
import fl1 from "../../assets/images/FL1.jpg"
import fl2 from "../../assets/images/FL2.jpg"
import fl4 from "../../assets/images/FL4.jpg"
import fl5 from "../../assets/images/FL5.jpg"
import fl6 from "../../assets/images/FL6.jpg"
import fl7 from "../../assets/images/FL7.jpg"

const ConcertinaPlana = () => {
    const items = [
        {
            src: fl1, 
            alt: "trabalho realizado com concertina plana"
        },
        {
            src: fl2, 
            alt: "trabalho realizado com concertina plana"
        },
        {
            src: fl4, 
            alt: "trabalho realizado com concertina plana"
        },
        {
            src: fl5, 
            alt: "trabalho realizado com concertina plana"
        },
        {
            src: fl6, 
            alt: "trabalho realizado com concertina plana"
        },
        {
            src: fl7, 
            alt: "trabalho realizado com concertina plana"
        }
    ]

    return (
        <Container className="produto-container">
            <div className="texto-produto">
                <h2 className="header-produto">Concertina Plana (ou FlatWrap)</h2>
                <article>
                    Atualmente, a concertina é empregada com fins de segurança. Por ser projetada para produzir cortes profundos em quem tentar ultrapassar, 
                    serve como barreira psicológica para invasores em potencial.<br/><br />
                    Um dos maiores benefícios da concertina flat é que, além de ser possível sua instalação em locais que não suportam a instalação de outras concertinas,
                    ela apresenta um padrão estético superior quando comparada aos outros tipos de concertinas. Isso ocorre porque o metal acaba sendo “achatado”, 
                    reduzindo o espaço ocupado pelo produto e dando um ar de leveza para o local em que ele é instalado. <br/><br/>
                    
                    Além disso, a concertina flat apresenta a mesma eficácia de proteção dos outros tipos de concertina. A segurança do seu imóvel fica 
                    garantida!<br /><br />
                </article>
            </div>
            
            {items && 
                <div>
                    <h2 className="header-produto">Algumas instalações realizadas</h2>
                    <div className="produto-work-gallery">
                        {items && items.map((item) => (
                            <img src={item.src} alt={item.alt}></img>
                        ))}
                    </div>
                </div>
            }
            
        </Container>
    )
}
export default ConcertinaPlana