import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <div className='footer-container'>
        <div className='footer-link-wrapper'>
          <h2>Contatos</h2>
          <div className='footer-link-items'>
            <div><a href="https://wa.me/5521996390446" target="_blank" rel="noreferrer" className="footer-whatsapp"><i className="fab fa-whatsapp contact-icon"> </i> (21) 99639-0446 </a></div>
            <div><a href="mailto:concertinaorcamento@gmail.com" className="footer-mail"><i className="fas fa-envelope contact-icon"> </i> concertinaorcamento@gmail.com</a></div> 
          </div>
          <div className="footer-link-items-centered">
            Rua Goias 718 loja C - Piedade - Instalamos em todo Estado do Rio de Janeiro
          </div>
          <div className="footer-copyright">
            <div><i className="far fa-copyright"></i> 2021 Concertina Segurança.</div>
            <div><i className="fas fa-code"></i> Desenvolvido por <a href="https://www.linkedin.com/in/gabriel-monteiro-b8a860180/" target="_blank" rel="noreferrer">Gabriel Monteiro</a></div>

          </div>
        </div>
    </div>
  );
}

export default Footer;
