import React from "react"
import { Container } from "reactstrap"
import ImageCarousel from "../components/ImageCarousel"
import cristo from '../assets/images/cristo.jpg'
import cd0 from '../assets/images/cd0.jpg'
import rl3 from "../assets/images/rl3.jpg"
import fl0 from '../assets/images/fl0.jpg'
import ce6 from '../assets/images/ce6.jpg'
import lm11 from '../assets/images/lm11.jpg'

import "./Home.css"
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet"

const Home = () => {
    
    const position = [-22.8886381, -43.3116125]
    const items = [
        {
            src: cristo,
            altText: 'Imagem Rio de Janeiro',
        },
        {
            src: lm11,
            altText: 'Lança para Muro',
        },
        {
            src: ce6,
            altText: 'Cerca Elétrica',
        },
        {
            src: rl3,
            altText: 'Rede Laminada',
        },
        {
            src: fl0,
            altText: 'Concertina Flatwrap',
        },
        {
            src: cd0,
            altText: 'Concertina Dupla Clipada',
        },
    ]
    
    return (
        <Container className="home-container">
            <ImageCarousel 
                items ={items}
            />

            <div className="texto-concertina">
                <h2 className="header-concertina">O que é Concertina ?</h2>
                <article>
                    No Brasil, a utilização de barreiras de segurança laminadas (mundialmente conhecida como concertinas, podendo ser chamada de ouriço, arame 
                    farpado moderno, espertina, navalha, etc.) com sua forma espiralada ou planas com lâminas de quatro pontas cortantes, pontiagudas e penetrantes 
                    substitui com inúmeras vantagens as cercas elétricas (dispensando manutenção e gastos de energia), como locais com plantas, telhados, locais 
                    baixos, etc. Comparadas com o ultrapassado arame farpado de fácil destruição, nos diferenciando pela resistência e qualidade de nossos produtos 
                    em GALVALUME (aço galvanizado com Alumínio), aço galvanizado, inox e material com pintura (com uma grande variedades de cores).
                </article>
            </div>

            <div className="mapa-container">
                <h2 className="header-concertina">Onde nos encontrar?</h2>
                <MapContainer 
                    className={"mapa"} 
                    center={position} 
                    zoom={27}
                    zoomControl={false}
                >
                    <TileLayer
                        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />        
                    <Marker
                        position={position}>
                        <Popup>
                            Rua Goias 718 loja C - Piedade
                        </Popup>
                    </Marker>
                </MapContainer>
            </div>
        </Container>
    )
}

export default Home