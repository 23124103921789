import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './screens/Home';
import SpikeWall from './screens/produtos/SpikeWall'
import Footer from "./components/Footer"
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import CercaEletrica from './screens/produtos/CercaEletrica';
import RedeLaminada from './screens/produtos/RedeLaminada';
import ConcertinaPlana from './screens/produtos/ConcertinaPlana';
import ConcertinaDuplaClipada from './screens/produtos/ConcertinaDuplaClipada';

function App() {
  return (
    <>
      <div className="main">
        <Router>
          <Navbar />
          <Switch>
            <Route path='/' exact component={Home} />
            <Route path='/lanca' component={SpikeWall} />
            <Route path='/cerca-eletrica' component={CercaEletrica} />
            <Route path='/rede-laminada' component={RedeLaminada} />
            <Route path='/concertina-plana' component={ConcertinaPlana} />
            <Route path='/concertina-dupla-clipada' component={ConcertinaDuplaClipada} />
          </Switch>
        </Router>
      </div>
      <Footer/>
    </>
  );
}

export default App;
