import React from "react";
import { Container } from "reactstrap";
import "./Produtos.css"
import cd1 from "../../assets/images/CD1.jpg"
import cd2 from "../../assets/images/CD2.jpg"
import cd3 from "../../assets/images/CD3.jpg"
import cd4 from "../../assets/images/CD4.jpg"

const ConcertinaDuplaClipada = () => {
    const items = [
        {
            src: cd1, 
            alt: "trabalho realizado com concertina dupla clipada"
        },
        {
            src: cd2, 
            alt: "trabalho realizado com concertina dupla clipada"
        },
        {
            src: cd3, 
            alt: "trabalho realizado com concertina dupla clipada"
        },
        {
            src: cd4, 
            alt: "trabalho realizado com concertina dupla clipada"
        }
    ]

    return (
        <Container className="produto-container">
            <div className="texto-produto">
                <h2 className="header-produto">Concertina Dupla Clipada</h2>
                <article>
                    Um dos modelos de cerca mais utilizadas é a concertina dupla clipada, normalmente confeccionada em aço. Apresenta um grau maior de proteção, 
                    uma vez que se trata de um produto que possui a duplicação da volta do material. <br/><br/>

                    A Concertina Dupla possui 3 clips de aço por volta, tornando-a diferente da Concertina Simples. É indicada para áreas que exigem mais segurança. 
                    Isso garante à concertina mais durabilidade de uso. Também dispensa acionamento de qualquer sistema para funcionamento, o que lhe confere 
                    praticidade e apresenta excelente efeito estético. <br/><br/>
                    
                    <b>Vantagens na utilização de concertinas duplas:</b><br /><br />
                    <b>Resistência</b><br />
                    Devido ao material utilizado para a confecção, a concertina dupla clipada é bastante resistente, sendo eficazmente empregada em locais externos, 
                    com mudanças climáticas variadas. Assim, mesmo em ambientes favoráveis à corrosão e também aos processos abrasivos, a concertina dupla clipada 
                    possui desempenho elevado.<br/><br/>

                    <b>Durabilidade</b><br />
                    A concertina dupla clipada possui grande durabilidade e, devido ao modo em que é confeccionada, pode ser empregada em locais diversos.<br/><br/>

                    <b>Manutenção</b><br />
                    O material utilizado na produção da concertina dupla possibilita que haja grande resistência na peça. Assim, a necessidade de manutenção é 
                    reduzida e a cerca dificilmente é lesada com corte ou lesões causadas em situações de risco.<br /><br />
                </article>
            </div>
            
            {items && 
                <div>
                    <h2 className="header-produto">Algumas instalações realizadas</h2>
                    <div className="produto-work-gallery-lg">
                        {items && items.map((item) => (
                            <img src={item.src} alt={item.alt}></img>
                        ))}
                    </div>
                </div>
            }
            
        </Container>
    )
}
export default ConcertinaDuplaClipada