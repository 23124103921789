import React from "react";
import { Container } from "reactstrap";
import "./Produtos.css"
import lm2 from "../../assets/images/lm2.jpg"
import lm4 from "../../assets/images/lm4.jpg"
import lm8 from "../../assets/images/lm8.jpg"
import lm9 from "../../assets/images/lm9.jpg"
import lm10 from "../../assets/images/lm10.jpg"
import lm11 from "../../assets/images/lm11.jpg"

const SpikeWall = () => {
    const items = [
        {
            src: lm2, 
            alt: "trabalho realizado com lança para muro"
        },
        {
            src: lm4, 
            alt: "trabalho realizado com lança para muro"
        },
        {
            src: lm8, 
            alt: "trabalho realizado com lança para muro"
        },
        {
            src: lm9, 
            alt: "trabalho realizado com lança para muro"
        },
        {
            src: lm10, 
            alt: "trabalho realizado com lança para muro"
        },
        {
            src: lm11, 
            alt: "trabalho realizado com lança para muro"
        }
    ]

    return (
        <Container className="produto-container">
            <div className="texto-produto">
                <h2 className="header-produto">Lança para Muros (ou SpikeWall)</h2>
                <article>
                    A Lança para muros é usada para aumentar a segurança em comércios, indústrias e residências. 
                    Tem como uma das vantagens dispensar códigos de segurança e manutenções frequentes. 
                    Ou seja, é um dispositivo simples, econômico e eficaz de segurança. 
                    Na prática, é uma medida bastante recomendada contra roubos, vandalismo e invasões que prejudicam seu patrimônio, 
                    tiram seu sossego e podem causar prejuízos econômicos.<br /><br />
                    
                
                    <b>Vantagens na utilização de lanças para muros:</b><br /><br />
                    <b>Resistência</b><br />
                    Por ser feita em aço resistente as mudanças climáticas e a cortes e rompimentos, a Lança é durável e não necessita de grandes manutenções.<br/><br/>

                    <b>Segurança</b><br />
                    A Lança desestimula a invasão ao imóvel. O meliante ao ver que não há espaço suficiente para pular o muro, acaba desistindo e os mais ousados, que tentam invadir, acabam feridos.<br/><br/>

                    <b>Instalação</b><br />
                    A instalação deve ser feita por empresas ou pessoas especializadas. É necessário fixar bem o suporte de aço, onde se encontra as lanças, para que não tenha perigo em cair e machucar as pessoas que passam pelo local.<br /><br />
                </article>
            </div>
            
            {items && 
                <div>
                    <h2 className="header-produto">Algumas instalações realizadas</h2>
                    <div className="produto-work-gallery">
                        {items && items.map((item) => (
                            <img src={item.src} alt={item.alt}></img>
                        ))}
                    </div>
                </div>
            }
            
        </Container>
    )
}
export default SpikeWall